











































































import { Component, Vue } from 'vue-property-decorator'
import { getOrderDetail } from '@/pages/videoGame/api'
import { callAppFunc_copyMess } from '@/common/utils/bridge'
import { getAppLanguage } from '@/common/utils'
@Component
export default class extends Vue {
  id = this.$route.query.id as string
  statusMap: any = {
    0: '',
    1: '待发货',
    2: '待收货',
    3: '已完成'
  }
  language = getAppLanguage()
  orderInfo = {
    id: '',
    orderNo: '',
    goodsId: '',
    goodsName: '',
    goodsPic: '',
    useIntegral: '',
    addressId: '',
    userName: '',
    userPhone: '',
    address: '',
    exchangeTime: '',
    orderStatus: 0,
    weCharName: '',
    weCharCodeForOrder: '',
    haveJump: 1
  }
  async getData() {
    const { data } = await getOrderDetail({ id: this.id })
    this.orderInfo = data
  }
  copyTextToClipboard() {
    callAppFunc_copyMess(this.orderInfo.weCharName)
  }
  async goGoodsDetail() {
    if (!this.orderInfo.haveJump) {
      return
    }
    this.$router.push({
      name: 'goodsDetail',
      query: {
        id: this.orderInfo.goodsId
      }
    })
  }
  goodsNameTrans(str: string) {
    if (this.language == 'zh_CN') {
      return str
    } else {
      return str.replace(/金币/, ' tagga')
    }
  }
  created() {
    this.getData()
  }
}
